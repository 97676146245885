.menu-link-group-button {
  background: #eceff1;
  border: 1px solid #cfd8dc;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 4px 12px;
  margin-right: 10px;
  color: #444444;

  &:hover {
    border: 1px solid #3655ba;
    color: #3655ba;
  }
}

.menu-delete-button {
  background: #d9534e;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  border-radius: 3px;
  padding: 8px;
}

.input-group-width {
  width: 100%;
  height: 20px;
}
.sublink-indent {
  padding-left: 90px;
}

.link-group-footer {
  position: absolute;
  left: 20px;
  top: 100%;
  padding-bottom: 20px;
}
