.markdownEditor {
  position: relative;
  margin-top: 15px;

  textarea {
    padding: 15px;
    font-family: $f-mono;
  }
  .tab-nav {
    position: absolute;
    right: 0;
    bottom: 100%;
  }
  .tab-link {
    display: inline-block;
    padding: 5px 5px 0;
    font-size: $f-size-xs;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    cursor: pointer;
    color: $c-grey-dark;
    border-bottom: 1px dotted $c-white;
    &:hover {
      text-decoration: none;
      color: $c-black;
      border-bottom: 1px dotted $c-black;
    }
    &.tab-link-active {
      color: $c-primary;
      font-weight: bold;
      border-bottom: 1px solid $c-primary;
    }
  }
  .tab-content {
    float: left;
    width: 100%;
  }
}

.markdownEditor,
.markdown-viewer {
  blockquote {
    max-width: 66%;
    padding: 0;
    font-style: italic;
    font-weight: 300;
    border: 0;
    p {
      font-size: $f-size-lg;
    }
  }
  code {
    padding: 3px 6px 2px;
    position: relative;
    top: -2px;
    font-size: $f-size-sm;
    margin-right: 4px;
    color: $c-primary;
    background-color: $c-grey-lighten;
  }
  p a,
  td a {
    display: inline;
    color: $c-primary;
    border-bottom: 1px solid $c-primary-light;
    text-decoration: none !important;
    padding: 0 2px;
    &:hover {
      color: $c-primary;
      border-color: $c-primary;
    }
  }
  img {
    max-width: 100%;
  }
  p,
  li {
    font-family: $f-regular;
    font-size: $f-size-lg;
    line-height: 1.5em;
  }
  li {
    margin-bottom: 5px;
  }

  table {
    width: 100%;
    table-layout: fixed;
  }
  th,
  td {
    padding: 7px 10px;
  }
  th {
    background-color: $c-grey-lighten;
    border-bottom: 1px solid $c-grey-light;
  }
  th:not(:last-of-type) {
    border-right: 1px solid $c-white;
  }
  tr:not(:last-of-type) {
    border-bottom: 1px solid $c-grey-light;
  }
  h1 {
    margin-top: 60px;
  }
  h2 {
    margin-top: 45px;
  }
  h3 {
    margin-top: 30px;
  }
  h4 {
    margin-top: 15px;
  }
}

.panel-body {
  .markdownEditor {
    margin-top: 15px;
  }
  .tab-link {
    padding: 15px 15px 0;
    font-size: $f-size-sm;
  }
}

.side-panel {
  .markdownViewer,
  .markdownEditor {
    p {
      font-size: $f-size;
    }
  }
}

.item {
  .markdownViewer,
  .markdownEditor {
    p {
      font-size: $f-size-sm;
    }
  }
}

.video-container > div:first-child {
  position: relative;
  padding-bottom: 73.25%;
  height: 0;
  overflow: hidden;
  margin-top: 5px;
  margin-bottom: 5px;
}
.video-container iframe,
.video-container object,
.video-container embed {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-container.compact > div:first-child {
  height: 0;
  padding-bottom: 0;
  position: relative;
  padding-top: 56.25%;

  .react-player {
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.video-container .timelink {
  display: flex;
  cursor: pointer;
  border-radius: 3px;
  padding: 8px 16px;

  &:first-child {
    margin-top: 16px;
  }
  &:nth-child(odd) {
    background: $c-grey-lighten;
  }

  &:hover {
    background: $c-primary-lighten;
  }

  b {
    margin-right: 16px;
    white-space: nowrap;
  }

  .fa {
    color: $c-grey-light;
    margin-right: 5px;
  }

  &:hover .fa {
    color: $c-primary;
  }
}

.page-viewer .markdown-viewer > *:not(.hero) {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.page-viewer .item .video-container {
  padding-bottom: 0;
}

.side-panel,
.entity-viewer {
  .markdown-viewer {
    p,
    li {
      font-size: $f-size;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: bold;
      letter-spacing: 0;
      text-transform: none;
      margin: 10px 0 20px;
      padding: 0;
    }

    h1 {
      font-size: $f-size-xxl;
    }
    h2 {
      font-size: $f-size-xxl;
    }
  }
}
