.u-floatLeft {
  float: left;
  left: 0;
}

.u-floatRight {
  float: right;
  right: 0;
}

.full-width {
  width: 100%;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.only-desktop {
  display: none;

  @media (min-width: 1024px) {
    display: initial;
  }
}

.only-mobile {
  @media (min-width: 1024px) {
    display: none;
  }
}
