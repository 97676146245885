.document-viewer {
  width: 100%;
  height: 100%;
  &.with-header {
    height: calc(100% - 50px);
  }

  &.with-footer {
    height: calc(100% - #{$FooterHeight});
  }

  &.with-header.with-footer {
    height: calc(100% - #{$FooterHeight} - 50px);
  }

  padding: 15px 15px 0;
  transition: all 500ms;
  overflow-y: auto;

  @media (min-width: 1024px) {
    &.with-panel:not(.unpinned-mode),
    &.is-active:not(.unpinned-mode) {
      width: calc(100% - 400px);
    }
  }

  .cs-loader {
    height: 80px;
  }

  // Hack to solve issue selecting text in some documents
  div.c {
    pointer-events: none;
  }
  div.c > * {
    pointer-events: auto;
    padding-right: 100px;
  }
}

.document-viewer .main-wrapper {
  .raw-text {
    white-space: pre-line;

    @media (min-width: 1024px) {
      white-space: pre;
    }
  }

  div.raw-text.force-rtl {
    /*!rtl:ignore*/
    direction: rtl;
  }

  .force-rtl {
    /*!rtl:ignore*/
    direction: ltr;

    .textLayer {
      /*!rtl:ignore*/
      direction: rtl;
    }
  }
}

.page-viewer,
.library-viewer {
  position: relative;
  overflow: auto;
}

.document-article {
  padding: 80px 120px;
  background: $c-white;
  border-radius: 0 !important;
  border-bottom: 1px solid $c-grey;
  box-shadow: $box-shadow;
  position: relative;
  font-size: $f-size-lg;
  font-family: sans-serif;
}

.document-viewer {
  .highlight-rectangle {
    cursor: pointer;
  }

  a.reference {
    text-decoration: none !important;
    color: inherit;
  }

  &.connections a.reference {
    background-color: #feeeb4;
    cursor: pointer;
  }

  a.highlighted,
  &.connections a.highlighted {
    background-color: #feeeb4;
    cursor: pointer;
  }

  &.connections a.reference a.reference {
    background-color: transparent;
  }

  &.connections a.reference.is-active {
    background-color: #ffd84b;
    cursor: pointer;
  }

  .selection {
    .highlight-rectangle {
      cursor: default;
    }
  }
}

mark,
.mark {
  color: transparent !important;
  background-color: transparentize($c-primary, 0.8) !important;
  padding: 0 !important;
  mark,
  .mark {
    background-color: transparent !important;
  }
}

mark.searchTerm {
  background-color: transparentize(yellow, 0.6) !important;

  mark.searchTerm {
    background-color: transparent !important;
  }
}

.side-panel {
  .item {
    &.relationship-selected {
      background: $c-success;
    }

    &.disabled {
      opacity: 0.6;
      cursor: inherit;
    }
  }
}

.relationship-steps {
  display: flex;

  &.is-fixed {
    display: flex;
    position: fixed;
    z-index: 1;
    background: linear-gradient($c-background 0%, $c-background 70%, transparent 100%);
    top: $header-height;
    width: 100%;
    max-width: 900px;
    left: 0;
    padding: 15px;
    @media (min-width: 1024px) {
      left: auto;
      padding: 15px 0;
    }
  }

  .btn {
    padding: 5px 10px;
    margin: 12px 10px 12px 0;
    font-size: $f-size-sm;
  }
  h2 {
    font-size: $f-size-sm;
    padding-left: 20px;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    small {
      position: absolute;
      top: -2px;
      left: 0;
      height: 16px;
      line-height: 15px;
      border: 1px solid $c-grey;
      color: $c-grey-dark;
      width: 16px;
      text-align: center;
      border-radius: 50%;
      padding-left: 2px;
    }
  }
  button i {
    padding-right: 4px;
  }
}

.connections-list {
  list-style-type: none;
  line-height: 24px;
  padding: 15px;
  margin: 0 -15px;
  color: $c-grey-dark;
  border-bottom: 1px solid $c-grey-lighten;
  overflow-y: auto;
  max-height: 150px;
  font-size: 0.9em;

  li {
    cursor: pointer;
  }

  .fa {
    width: 20px;
    color: $c-grey-light;
  }

  .fa.fa-check {
    color: $c-success;
  }

  &-title {
    line-height: normal;
    margin-top: 25px;
    height: auto;
  }
}

.search-form {
  .form-group {
    position: relative;
    margin: 0 -15px;
    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 15px;
      color: $c-black;
      cursor: pointer;
    }
  }
  .form-control {
    padding-left: 40px;
    height: $header-height;
    line-height: $header-height;
    border-radius: 0;
    border: 0;
    color: $c-black;
    box-shadow: none !important;
    @include placeholder($c-black);

    &:focus {
      background: transparent;
      @include placeholder(transparent);
    }
  }
}

.create-reference {
  .item {
    -webkit-flex-direction: row;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: auto;
    padding: 5px 15px 5px 15px;
    border-color: $c-grey-light !important;
    margin-bottom: 0;

    &:hover {
      background-color: transparentize($c-primary-light, 0.7);
    }

    &:active,
    &.is-selected {
      box-shadow: inset 0 0 0 1px transparentize($c-primary, 0.7);
      background-color: transparentize($c-primary-light, 0.7);
    }

    .item-name {
      font-family: $f-regular;
      color: $c-grey-dark;
    }

    .item-actions {
      .btn-color {
        margin-right: 0;
      }
    }

    .search-form {
      margin: 0 -15px;
    }
  }
  .item-info {
    padding: 0;
  }
  .item-actions {
    position: relative;
    height: auto;
    padding: 0;
    margin-left: 15px;
  }
  .btn-color {
    width: auto;
    max-width: 75px;
  }
  .cs-loader-container {
    position: absolute;
    width: 100%;
    z-index: 1;
    overflow: hidden;
  }
}

.copy-from-panel {
  z-index: 7;
}

.copy-from {
  flex: 1;
  min-width: 50%;

  .copy-from-buttons {
    position: absolute;
    bottom: 2em;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 0 20px;
    text-align: center;
    white-space: nowrap;

    .btn {
      position: relative;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      line-height: 50px;
      font-size: 1.1em;
      padding: 0;
      margin: 0 12px;
    }

    .btn-label {
      color: #6c6c7b;
      position: absolute;
      top: 100%;
      font-size: 0.8em;
      line-height: 1.5em;
      left: 50%;
      transform: translateX(-50%) translateY(2px);
      background: rgba(255, 255, 255, 0.8);
      padding: 0 5px;
      width: 6em;
      white-space: pre-line;
    }
  }

  .search-box {
    position: sticky;
    top: 0;
    margin: 0;
    padding: 15px 15px 15px 15px;
    width: 100%;
    background: white;
    border-bottom: solid 1px lightgrey;

    .input-group {
      svg {
        top: 23px;
      }
    }
  }
}
