.item-group {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  justify-content: flex-start;
  margin-right: -15px;

  .item {
    margin-right: 15px;
    margin-bottom: 15px;
    //width: calc(33.33% - 15px);
  }
}

.item {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 100px;
  padding: 0 0 30px 0;
  top: 0;
  background: $c-white;
  border-radius: $border-radius;
  border: 1px solid $c-grey-light;
  box-shadow: $box-shadow;
  zoom: 1;
  cursor: pointer;

  &:hover {
    cursor: pointer;
    border-color: $c-grey;
  }
  &.is-active,
  &.is-selected {
    border-color: $c-primary;
    box-shadow: inset 0 1px 2px $c-grey-lighten, 0 0 0 3px transparentize($c-primary, 0.7);
  }

  &-source-document {
    border-bottom: 4px solid $c-grey-light;
  }

  .label {
    margin-right: 15px;
    font-size: $f-size-sm;
    font-weight: normal;
    margin-right: 5px;
    display: inline-block;
    line-height: 30px;
    .fa {
      margin-left: 0;
    }
  }
  span,
  dt,
  dd {
    &::selection {
      background: transparent;
    }
    &::-moz-selection {
      background: transparent;
    }
  }
}

.item-group-charts .item {
  overflow: hidden;
  width: 100%;
  margin-right: 0;

  .item-chart-type {
    float: right;
    margin-right: 5px;
    margin-top: 5px;

    button {
      margin-left: 3px;
      width: 30px;
      height: 30px;
      line-height: 30px;
      border-radius: 50%;
      padding: 0;
      text-align: center;
    }
  }

  p {
    font-weight: 300;
    font-family: $f-special;
    padding: 15px;
    margin: 0;
  }
}

$item-width: auto;
$item-padding: 15px;

.item-connection {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  background-color: $c-grey-light;
  color: $c-grey-dark;
  padding: 5px 5px 5px $item-padding;
  border-bottom: 1px solid $c-white;

  span {
    padding-left: 5px;
  }

  .btn {
    padding: 0 5px;
  }
}

.item-info {
  width: $item-width;
  padding: $item-padding;
  font-size: $f-size-sm;
}

.item-icon {
  position: relative;
  margin-right: 3px;
}

.item-private-icon {
  margin-right: 6px;
  width: 18px;
  height: 18px;
  line-height: 20px;
  text-align: center;
  background-color: transparentize($c-grey-dark, 0.85);
  color: $c-grey-dark;
  border-radius: 50%;
}

.item-type {
  display: -webkit-flex;
  -webkit-align-items: center;
  align-items: center;
  white-space: nowrap;
  width: calc(100% - 35px);
}

.item-label-group {
  width: calc(100% - 90px);
  .item-type {
    width: 100%;
  }
}

.item-type__icon {
  margin-right: 5px;
  font-size: $f-size;
}

.item-type__name {
  color: $c-white;
  border-radius: $border-radius;
  padding: 0 6px;
  height: 17px;
  line-height: 18px;
  font-size: $f-size-xs;
  font-weight: 500;
  letter-spacing: 0.075em;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  cursor: default;
}

.item-type__documentLanguage {
  &:before {
    content: '\00a0\00a0';
  }

  > span {
    color: $c-grey-dark;
    background-color: $c-language-highlight;
    border-radius: 0;
    padding: 0 4px;
    height: 17px;
    line-height: 18px;
    font-family: 'Roboto', sans-serif;
    font-size: $f-size-xs;
    font-weight: 500;
    letter-spacing: 0.075em;
    max-width: 100%;
    white-space: nowrap;
    display: inline-block;
    text-transform: uppercase;
  }
}

.item-type-empty .item-type__icon {
  color: $c-grey;
}
.item-type-primary .item-type__icon {
  color: $c-primary;
}
.item-type-0 .item-type__icon {
  color: $c-type-0;
}
.item-type-1 .item-type__icon {
  color: $c-type-1;
}
.item-type-2 .item-type__icon {
  color: $c-type-2;
}
.item-type-3 .item-type__icon {
  color: $c-type-3;
}
.item-type-4 .item-type__icon {
  color: $c-type-4;
}
.item-type-5 .item-type__icon {
  color: $c-type-5;
}
.item-type-6 .item-type__icon {
  color: $c-type-6;
}
.item-type-7 .item-type__icon {
  color: $c-type-7;
}
.item-type-8 .item-type__icon {
  color: $c-type-8;
}
.item-type-9 .item-type__icon {
  color: $c-type-9;
}
.item-type-10 .item-type__icon {
  color: $c-type-10;
}
.item-type-11 .item-type__icon {
  color: $c-type-11;
}
.item-type-12 .item-type__icon {
  color: $c-type-12;
}
.item-type-13 .item-type__icon {
  color: $c-type-13;
}
.item-type-14 .item-type__icon {
  color: $c-type-14;
}
.item-type-15 .item-type__icon {
  color: $c-type-15;
}
.item-type-16 .item-type__icon {
  color: $c-type-16;
}
.item-type-17 .item-type__icon {
  color: $c-type-17;
}
.item-type-18 .item-type__icon {
  color: $c-type-18;
}

.item-type-empty .item-type__name {
  background-color: $c-grey-light;
  color: $c-grey-dark;
  height: 1.5em;
}
.item-type-primary .item-type__name {
  background-color: $c-primary;
}
.item-type-0 .item-type__name {
  background-color: $c-type-0;
}
.item-type-1 .item-type__name {
  background-color: $c-type-1;
}
.item-type-2 .item-type__name {
  background-color: $c-type-2;
}
.item-type-3 .item-type__name {
  background-color: $c-type-3;
}
.item-type-4 .item-type__name {
  background-color: $c-type-4;
}
.item-type-5 .item-type__name {
  background-color: $c-type-5;
}
.item-type-6 .item-type__name {
  background-color: $c-type-6;
}
.item-type-7 .item-type__name {
  background-color: $c-type-7;
}
.item-type-8 .item-type__name {
  background-color: $c-type-8;
}
.item-type-9 .item-type__name {
  background-color: $c-type-9;
}
.item-type-10 .item-type__name {
  background-color: $c-type-10;
}
.item-type-11 .item-type__name {
  background-color: $c-type-11;
}
.item-type-12 .item-type__name {
  background-color: $c-type-12;
}
.item-type-13 .item-type__name {
  background-color: $c-type-13;
}
.item-type-14 .item-type__name {
  background-color: $c-type-14;
}
.item-type-15 .item-type__name {
  background-color: $c-type-15;
}
.item-type-16 .item-type__name {
  background-color: $c-type-16;
}
.item-type-17 .item-type__name {
  background-color: $c-type-17;
}
.item-type-18 .item-type__name {
  background-color: $c-type-18;
}

.item-name {
  display: inline;
  font-family: $f-special;
}

.item-snippet {
  font-size: $f-size-sm;
  line-height: 1.6em;
  b {
    font-weight: normal;
    color: #333300;
    background-color: transparentize(yellow, 0.8);
    box-shadow: -2px 0 0 0 transparentize(yellow, 0.4), 2px 0 0 0 transparentize(yellow, 0.4);
  }
}

.item-snippet-source {
  color: $c-grey-dark;
  font-size: $f-size-xs;
}

.item-snippet-wrapper {
  background: $c-card-highlight;
  margin: 15px -15px 0 -15px;
  padding: 5px 5px 5px 15px;
  a {
    font-size: $f-size-xs;
  }
}

.item-metadata {
  font-size: $f-size-sm;

  .print-view-alt {
    display: none;
  }

  dl {
    margin: 0 $item-padding;
    padding: 3px 0;
    font-size: $f-size-sm;

    &.metadata-type-multimedia dd {
      text-align: center;

      img {
        max-height: 200px;

        &.cover {
          width: 100%;
          object-fit: cover;
        }
      }

      .markdown-viewer {
        height: auto;
        &:after {
          display: none;
        }

        .timelink {
          display: none;
        }
      }
    }

    &:first-child {
      margin-top: $item-padding;
    }

    &:last-child {
      margin-bottom: $item-padding;
    }

    &.full-width {
      margin-left: 0;
      margin-right: 0;
      text-align: center;
    }
  }

  dt {
    margin: 6px 0 2px;
    font-style: normal;
    font-size: $f-size-xs;
    letter-spacing: 0.1em;
    opacity: 0.6;
  }

  dd {
    width: 100%;

    .item-count::before {
      content: '(';
    }

    .item-count::after {
      content: ')';
    }

    &.item-metadata-empty {
      color: $c-grey;
      font-style: italic;
    }

    &.item-current-sort {
      background-color: $c-card-highlight;
      padding: 3px 3px 1px;
      margin-left: -3px;
    }
  }

  .markdown-viewer p,
  .markdown-viewer li {
  }

  .markdown-viewer {
    position: relative;
    display: block;
    height: 10em;
    overflow: hidden;

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      height: 3em;
      width: 100%;
      background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      background: -webkit-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
    }
  }

  .item-metadata-crop {
    position: relative;
    display: block;
    height: 48px;
    overflow: hidden;

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      height: 36px;
      width: 100%;
      background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      background: -webkit-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
    }
  }
}

.item-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: $item-padding * 2 + $item-padding;
  color: $c-grey-dark;
  padding: 0 $item-padding;
  font-size: $f-size-sm;

  div {
    white-space: nowrap;
    margin-left: auto;
  }

  .btn,
  .btn-color {
    font-size: $f-size-sm;
  }

  .btn:not(:last-child) {
    margin-right: 8px;
  }

  .btn-color {
    white-space: nowrap;
    overflow: hidden;
    margin-right: 8px;
    text-overflow: ellipsis;
    max-width: 65%;
  }

  .property-help {
    color: #2b56c1;
    display: inline-block;
    text-align: center;
    line-height: 22px;
    font-size: 12px;

    position: static;
    width: 22px;
    height: 22px;
    left: 0px;
    top: 0px;

    background: #f2f7ff;
    border: 1px solid #2b56c1;
    box-sizing: border-box;
    border-radius: 50px;
    margin-left: 0;
  }
}

.item-shortcut {
  position: relative;
  bottom: 5px;
  padding: 0;
  border-radius: 15px;
  min-width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  display: inline-block;
  box-shadow: $box-shadow;

  @media (min-width: 1024px) {
    opacity: 0;
    .item.is-active &,
    .item:hover & {
      opacity: 1;
    }
  }

  &:not(:first-child) {
    margin-left: 3px;
  }
}

.item-shortcut-group {
  margin: 0 -5px;
}

.item-group-load-more {
  text-align: center;
}

.btn-load-more {
  width: 100px;
  margin: 0 5px 30px;
}

.page-title {
  text-align: left;
  margin: 0;
  padding-bottom: 15px;
  line-height: 16px;
  font-size: $f-size-lg;

  .label {
    font-size: $f-size-sm;
    position: relative;
    top: -3px;
    padding: 2px 5px;
  }
}

.search-list {
  width: 100%;
  margin: 0 auto 15px;

  &-actions {
    float: right;

    & button:not(:first-child) {
      margin-left: 3px;
    }
  }
}

.sort-by {
  position: relative;
  display: flex;
  gap: 15px;
  font-size: $f-size-sm;

  @media (min-width: 768px) {
    margin-top: 0;
  }
}

.item-danger {
  border-color: $c-danger-light;
  background-color: lighten($c-danger-light, 10%);
  &:hover,
  &.is-active {
    border-color: $c-danger;
  }
}

.item-status {
  .progress {
    margin: 0;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 4px;
  }

  .progress-bar {
    background: $c-grey;
  }

  .progress-bar-striped,
  .progress-striped .progress-bar {
    background-image: -webkit-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.5) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0.5) 75%,
      transparent 75%,
      transparent
    );
    background-image: -o-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.5) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0.5) 75%,
      transparent 75%,
      transparent
    );
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.5) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0.5) 75%,
      transparent 75%,
      transparent
    );
    -webkit-background-size: 40px 40px;
    background-size: 40px 40px;
  }

  &.item-processing {
    cursor: default;
  }

  &.item-processing,
  &.item-danger {
    .item-shortcut {
      display: none;
    }
  }
}

.label-default,
.label-primary,
.label-info,
.label-success,
.label-warning,
.label-danger {
  background: transparent;
  padding: 0;
}

.label-primary,
.label-success,
.label-warning,
.label-danger {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  .fa {
    margin-right: 3px;
  }
}

.label-info {
  color: $c-grey;
}

.label-primary {
  color: $c-primary;
}

.label-success {
  color: $c-success;
}

.label-warning {
  color: $c-warning;
}

.label-danger {
  color: $c-danger;
}

.label-default {
  color: $c-grey-dark;
}

.label-progress {
  display: inline;
}

.documents-counter {
  b {
    color: $c-primary;
  }
}

.item-success {
  .label-success {
    display: none;
  }
}
.item-warning {
  .item-shortcut--success {
    display: none;
  }
}
.item-label-group {
  .label-warning ~ span,
  .label-danger ~ span {
    display: none;
  }
}

.item-favorite {
  position: absolute;
  right: 5px;
  top: -2px;

  .btn:hover .tab-link-tooltip {
    z-index: 999;
    top: calc(100% + 7px);
  }

  .favoriteBanner {
    display: block;
    width: 16px;
    height: 14px;
    position: relative;
    padding: 6px;
    background-color: #dceff5;
    border: 1px solid #c6d8dd;
    border-radius: 2px 2px 0px 0px;

    &::before {
      content: '';
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 6px solid #c6d8dd;
      position: absolute;
      bottom: -6px;
      left: -1px;
    }

    &::after {
      content: '';
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 5px solid #dceff5;
      position: absolute;
      bottom: -5px;
      left: 1px;
    }

    &.selected {
      background-color: #ef008c;
      border-color: #d8007e;

      &::before {
        border-top-color: #b30068;
      }

      &::after {
        border-top-color: #ef008c;
      }
    }
  }
}
