.metadata-extraction-table {
  .table tr th {
    border-bottom: 0;
  }
  .table tr:first-of-type td {
    border-top: 0;
  }
  .table > tbody > tr > td {
    vertical-align: middle;
    text-align: left;
    padding: 10px 0px;
  }
}

.delete-selection {
  border: unset;
  background-color: transparent;
  padding: 6px 0;
  color: $c-primary;
  font-size: $f-size-xs;

  &:hover {
    text-decoration: underline;
  }
}
